import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    },
    copiedTextOverride: {
      type: String,
      default: null
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      tooltipMessage: 'Copy to clipboard',
      renderMarkdown: renderMarkdown
    };
  },
  methods: {
    handleCopyToClipboard: function handleCopyToClipboard() {
      var _this = this;
      navigator.clipboard.writeText(this.copiedTextOverride ? this.copiedTextOverride : this.text).then(function () {
        _this.tooltipMessage = 'Copied!';
        setTimeout(function () {
          _this.tooltipMessage = 'Copy to clipboard';
        }, 3000);
      });
    }
  }
});